import { render, staticRenderFns } from "./teacher.vue?vue&type=template&id=790238b2&scoped=true&"
import script from "./teacher.vue?vue&type=script&lang=js&"
export * from "./teacher.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790238b2",
  null
  
)

export default component.exports